import { extendTheme } from '@chakra-ui/react'
/* import "@fontsource/nunito-sans"
import "@fontsource/nunito-sans/800.css" */
import "@fontsource/jost"


export const theme = extendTheme({
  fonts: {
    heading: `'Jost', sans-serif` ,
    body: `'Jost', sans-serif`,
  },
 
})
/* heading: `'Nunito Sans/800', sans-serif`,
body: `'Nunito Sans/800', sans-serif`, */
