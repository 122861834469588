import {
    Container,
    Stack,
    Flex,
    Box,
   
   Hide,
    Text,
   Button,
    
    Image,
    Icon,
 /*    IconProps, */
    Center,
    HStack,
    Menu,
    Link
  } from '@chakra-ui/react';
  import {motion} from "framer-motion"
/*   import { useToast } from "@chakra-ui/core"; */


 
  import bgvid from "../assets/bgvid.mp4";  
/*   import {useState, useEffect} from "react" */



 
  


  export default function Hero() {
  
   /*  const [isVisible, setIsVisible] = useState(false); */
    
   /*  const scrollToTop = async() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }; */
  
/*     useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset < 1000) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener('scroll', toggleVisibility);
  
      return () => window.removeEventListener('scroll', toggleVisibility);
    }, []); */
  

    return (
        <>
        <Box id={"Dashboard"} zIndex={4} activeclass={"active"}marginTop={"-20px"}>
        

        

       <Box overflow={"hidden"}  >
          <Box transition={"0.6s"} maxHeight={{base:"300vw",sm:"300vw"}} minWidth={{base:"300vh",sm:"300vw", md:"100vw"}} objectFit={"cover"} w={{base:"200vw",sm:"300vh"}}>
 
        {   <video preload="auto" objectFit={"cover"} alt='ocean waves' overflow={"hidden"}style={{filter: "grayscale(0.2)"}} type="video/mp4" src={bgvid} playsInline autoPlay={true} controls={false}loop={true} muted >
        
            </video>    


          
              }  
        
          </Box>
          </Box>

        <Center>
          
          {/* margin herobox */}
       <Container  marginTop={{ base: '8em', sm: '10em', md:"9em", lg: '4em', xl:"5em" }}   justifyContent={"center"} position={"absolute"}   className='content' activeclass={"actives"} transition={"0.2s"} maxW={"100vw"}  > 
  
        <Stack   id="flask"mb={"20px"} mt={"-100px"}
       
        justifyContent={"center"} 
        alignItems={"center"} 
          align={'center'}
          
         
          pt={{ base: 20, md: 28 }}
         
          direction={{ base: 'column-reverse',sm:"column-reverse", md: 'row' }}>
              
          <Stack maxWidth={{base:"30vw",sm:"40vw"}} id="ost"py={2}alignItems={"center"} justifyContent={"center"} borderRadius={"10"} flex={1}/* bg={"rgba(255, 255, 255, 0.2)"}  *//* backdropFilter="auto" backdropBlur="2px" */>
 
    
            
            <Box zIndex={1} marginTop={{base:"-5vh", sm:"-10vw",md:"5vw"}} position={"relative"} transition={"0.8s"} marginLeft={{base:"15vw", md:"5em"}} marginBottom={"-4em"} w={{base:"70vw", sm:"25em", md:"25em", lg:"35em"}}>
            <motion.div whileTap={{ scale: 0.8 }} >
       <svg  backdropFilter="auto" backdropBlur="20px" id="signature" 
   x="0px" y="0px" viewBox="0 0 1920 1080"  >

<path id='signaturos' class="stroke-an" d="
  M61,658.5c-1.2,5.4,1.2,10.2,6,12.9c5.4,3.2,11.7,2.1,16.8-1.2c6.7-4.4,11.6-11.7,16.5-17.9c5.2-6.5,10.2-13.1,15.1-19.7
  c20.9-28.2,39.6-58,56.1-89c32.7-61.7,56.5-128.1,70.1-196.6c7.6-38.3,12.1-77.2,13.4-116.2c-2,0-4,0-6,0
  c7.4,120,17.6,240.9,49.6,357.3c9.1,33.1,20,65.6,33,97.4c1.7-1,3.3-1.9,5-2.9c-59.2-67.5-121.8-131.9-187.5-193
  c-2-1.9-6.2,0-5,2.9c7.5,18.5,22.1,31.8,38.6,42.4c20.6,13.3,43.2,23.8,65,35c23.9,12.2,48,24.2,72.2,35.8
  c22.4,10.7,45.3,21.1,69.7,26.2c10.8,2.3,23.2,4.7,34.1,1.6c9.8-2.8,16.5-10.6,22.3-18.6c12.9-17.8,22.3-37.9,28.7-58.9
  c13.3-43.4,14.3-89.5,12.4-134.5c-2.6-59.9-9.7-120.7,2.9-180c-2-0.3-3.9-0.5-5.9-0.8c-4.2,102.1,1.3,204.6,16.5,305.7
  c4.4,29,9.5,57.9,15.5,86.7c1.9-0.5,3.9-1.1,5.8-1.6c-14.1-35.2-40.1-65.1-73.5-83.2c-16.7-9-36.2-15.4-55.3-12.5
  c-15.6,2.4-29.3,11.5-36.4,25.7c-13,25.9-5.3,60.5,15.6,79.9c27.5,25.5,68,29.2,103.7,25.5c41.6-4.3,82.3-17.3,118.9-37.5
  c17.3-9.6,35.2-20.6,49.3-34.5c11.8-11.6,21.4-28.6,14.2-45.2c-5.8-13.2-21.2-23.2-34.8-14.5c-7.5,4.8-12.3,13.5-16.3,21.1
  c-4.7,9-8.4,18.6-10.8,28.5c-4.8,19.2-6.2,41.2,0.5,60.1c5.7,16.2,18,28.1,34,34.1c36.7,13.9,73.4-5.8,100.3-30.2
  c15.6-14.2,29.7-30.1,42.5-46.9c6.7-8.8,14-17.9,18.9-27.8c3.7-7.4,5.5-16,1.4-23.6c-3.5-6.4-9.7-10-16.9-9
  c-0.9,0.1-1.9,1.2-2.1,2.1c-6.3,33.7-5.9,78.9,23.7,102.1c34.6,27.1,81.2,3.8,102.6-28.3c15.2-22.8,22-49.9,28.5-76.1
  c0.8-3.2-4-5.5-5.5-2.3c-10.2,21.1-16.7,43.9-18.7,67.3c-1.4,16.8,1,37.8,18.4,45.6c18.1,8.1,38.9-3.5,53.5-13.8
  c16.1-11.3,30.1-25.3,41.5-41.2c13.4-18.7,23.1-39.9,28.3-62.3c-1.9,0-3.9,0-5.8,0c12.6,31.6,2.4,74,32.8,97.2
  c29.4,22.4,63.6-11.3,79.9-34.5c11.4-16.2,19.2-34.9,22.6-54.4c0.7-3.7-4.9-5.4-5.8-1.6c-2.6,11.4-5.2,22.7-7.8,34.1
  c-1.2,5.3-2.4,10.6-3.6,15.9c-1.1,4.8-2.5,9.9-1.2,14.8c3.4,13.1,19.8,13.1,30.3,9.9c15.3-4.6,29.7-15.9,40.8-27.1
  c12.5-12.5,22.1-27.5,28.5-43.9c1.4-3.6-4.4-5.2-5.8-1.6c-10.9,27.7-32.1,51.2-58.9,64.3c-10.8,5.3-32.3,8.2-29-10.3
  c3.3-18.3,8.3-36.4,12.4-54.5c-1.9-0.5-3.9-1.1-5.8-1.6c-3.6,20-11.6,38.9-23.7,55.2c-6.1,8.3-13.3,15.8-21.2,22.4
  c-8.4,7-18.6,14.1-29.9,14.4c-19.7,0.6-32.4-19.3-37-36.1c-5.8-21.1-3.8-43.6-12.1-64.1c-1-2.5-5-3.2-5.8,0
  c-8.1,34.7-27.2,66.3-54.1,89.8c-13.3,11.6-30.8,24.9-49.2,25.7c-21.4,0.9-27.6-19.4-27-37.2c0.9-23.8,7.9-47.8,18.2-69.2
  c-1.8-0.8-3.7-1.5-5.5-2.3c-9.9,39.7-22,85.7-62.1,105.1c-17.4,8.4-38.2,9.9-55-0.6c-14.4-8.9-22.4-24.4-26.1-40.4
  c-4.5-19.1-3.6-39.4,0-58.5c-0.7,0.7-1.4,1.4-2.1,2.1c5.3-0.7,9.6,3.9,11.1,8.6c1.6,5.1-0.1,10.6-2.4,15.2
  c-4,8.1-9.8,15.5-15.1,22.7c-11.2,15.1-23.6,29.4-36.9,42.7c-23.5,23.3-54.3,45.7-89.3,40.3c-14.8-2.3-29.3-9.5-37.8-22.2
  c-10-15-11-34.5-9-51.9c2-18.4,8.2-36.6,18.2-52.2c4.7-7.3,11.7-14.9,21.3-12.1c7.3,2.2,12.8,9.1,15.1,16.2
  c5.4,16.8-9,32.4-20.9,42.3c-13.7,11.3-29.1,20.9-44.7,29.3c-31.5,16.9-65.9,28.2-101.2,33.4c-32.1,4.7-68,4.8-96.8-12.2
  c-24-14.1-38.1-41.3-32.8-69.2c2.3-12.3,8.8-23.7,19.7-30.4c16-9.8,36.1-7.4,52.9-1.2c31.2,11.4,58,35.9,74.9,64.2
  c4.3,7.2,8,14.7,11.1,22.5c1.4,3.5,6.6,2.3,5.8-1.6c-20.7-100.3-31.9-202.6-33.1-305.1c-0.3-28.6,0.1-57.1,1.3-85.7
  c0.1-3.3-5.2-4.1-5.9-0.8c-19.9,93.7,8.9,189.6-7.9,283.6c-3.8,21.1-9.8,42-19.2,61.3c-4.6,9.5-10,18.6-16.2,27.1
  c-7.9,10.8-16.3,17.5-30.3,16.9c-24.5-1-48.8-10.5-70.9-20.5c-23-10.3-45.5-21.8-68-33.1c-24.7-12.4-49.5-24.7-73.6-38.2
  c-19.5-11-39.5-24.6-48.3-46.2c-1.7,1-3.3,1.9-5,2.9c65.8,61.1,128.4,125.5,187.5,193c1.9,2.2,6.2-0.1,5-2.9
  c-45.6-111.9-64.6-232.2-75.1-352c-2.9-33.6-5.2-67.3-7.3-101c-0.2-3.8-5.9-3.9-6,0c-4.1,127.9-43.7,254.1-113.1,361.7
  c-9.4,14.6-19.4,28.9-29.9,42.7c-5.5,7.3-11.1,14.5-16.9,21.5c-2.8,3.4-5.8,7.1-9.8,9.2c-4.4,2.3-14.1,1.3-12.4-5.9
  C67.7,656.4,61.9,654.8,61,658.5L61,658.5z"/>

</svg>
</motion.div>
</Box>  <Box id="textbox" background={"rgba(255, 255, 255, 0.01)"}  >
            <Box marginLeft={{base:"200px", sm:"20em"}}    transition={"0.8s"} display={"inline"} borderRadius={20} > </Box>
            <motion.div
  initial={{opacity: 0}}
  whileInView={{opacity: 1}}
  viewport={{ once: false }}
  transition={{delay: 0.2}}
>
            <Flex flexDirection={"column"} alignItems={"start"}>
            
            <Text px={"1"} display= "inline-block"  w={"50"} borderRadius={"4px"} bg={"rgba(255, 255, 255, 0.01)"} borderColor={"rgba(255, 255, 255, 0.1)"} backdropFilter="auto" backdropBlur="80px" color={"gray.700"} fontSize={{ base: '0.8rem', sm: '1rem', md:"1rem", lg: '1rem', xl:"1rem" }} fontWeight={"600"}  >Adam Österlund</Text>
           
            <Text display= "inline-block" borderColor={"rgba(255, 255, 255, 0.1)"} backdropFilter="auto" backdropBlur="80px" borderRadius={"4"} bg={"rgba(255, 255, 255, 0.4)"} color={"gray.800"} fontSize={{ base: '1.5rem', sm: '1.5rem', md:"1.5rem", lg: '1.5rem', xl:"1.5rem" }} fontWeight={"900"} marginBottom={"2"}>Frontend developer</Text>
          

            </Flex>
            </motion.div>
 
             <Box  borderRadius={"4px"} bg={"rgba(255, 255, 255, 0.1)"} borderWidth={2} borderColor={"rgba(255, 255, 255, 0.1)"}backdropFilter="auto" backdropBlur="60px"> 
             <motion.div
  initial={{opacity: 0}}
  whileInView={{opacity: 1}}
  viewport={{ once: false }}
  transition={{delay: 0.2}}
>
            <Text padding={2}  w={{base: "75vw",sm:"20em", md:"20em"}} color={'gray.800'} borderRadius={"2px"}     fontWeight={"500"} fontSize={{ base: '1rem', sm: '1rem', md:"1rem", lg: '1rem', xl:"1rem" }}   >
            With background in design studies and tutoring I take interest in UX and accessibility
            </Text>
           

            </motion.div>
             </Box> 
           
            </Box>
         
       
             
          
          </Stack>

          <motion.div whileTap={{ scale: 0.8 }} >
          <Flex
           
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            bgColor={"rgb(247, 202, 24, 0.9)"}
            zIndex={-0.2}
            borderStyle={"groove"}
            border={90}
            borderRadius={2000}
            maxWidth={{base:"53vw", sm:"45vw", md:"25em"}}
            >
            <Box
             
              position={'relative'}
          
             
             id="nexttoim"
              overflow={'hidden'}>
   
             
              <Image
              
             
             
              borderRadius={2000000}
              width={{base:"20em",  md:"30em"}}
              
             
              height={"auto"}
                alt={'Hero Image'}
               
               
                align={'center'}
                zIndex={-5}
             
               
                transition={"0.8s"}
               
                 rel="preload" 
                loading="eager"
                backdropFilter="auto" backdropBlur="2px"
                src={ "https://i.postimg.cc/sfcZf5XL/adampp.webp"
              
                }
              />
             
            </Box>
           
          </Flex>
          </motion.div >
        </Stack>
     


      <Box display={"flex"} justifyContent={"center"}   >
      <HStack marginTop={{base:"-1em",sm:"1vh"}} borderStyle={"inset"} borderRadius={8} w={{base:"14em",sm:"15em",md:"17em"}} height={{base:"4em",sm:"5em",md:"6em"}} spacing='24px' display={"flex"} justifyContent={"center"}  bg={"rgba(255, 255, 255, 0.1)"}  borderWidth={2} borderColor={"rgba(255, 255, 255, 0.1)"}backdropFilter="auto" backdropBlur="80px" >
        
      <Menu borderRadius={"8px"}      >
     <Link href={"https://www.linkedin.com/in/adam-%C3%B6sterlund-571930252/"}  isExternal>
  <Icon animation={"fadeIn 2s ease-in  "}  w={{base:"2.92em",sm:"3.42em",md:"3.42em"} } height={"auto"} color='gray.700'
             transition={"0.8s"} _hover={{base:{w:"3.42em", h:"auto"}, sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}}>
               
    <path fill="currentColor" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
    
  </Icon>
  </Link>  
  <Link href={"https://github.com/Adamsalvius"} isExternal>
  <Icon animation={"fadeIn 2.5s ease-in  "}  w={{base:"3em",sm:"3.5em",md:"3.5em"} } height={"auto"}  color='
  gray.700' transition={"0.8s"} _hover={{base:{w:"3.5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}}>
  <path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
  </Icon>
  </Link>
  
              <Link href='https://www.instagram.com/adismarmeladis/' isExternal>
  <Icon animation={"fadeIn 3s ease-in  "} animationDelay={"5s"}  w={{base:"3em",sm:"3.5em",md:"3.5em"} } height={"auto"}   color="gray.700" transition={"0.8s"} _hover={{base:{w:"3.5em", h:"auto"},sm:{w:"4.0em", h:"auto"}, md:{w:"4em", h:"auto"}}} >
  <path  fill="currentColor" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
  </Icon></Link>
 

  </Menu>
  
  
</HStack>



</Box>

<Center>
 <Box display={"flex"} width={"80vw"}  justifyContent={"center"} alignContent={"center"}>
   
<Box    /* fontWeight={"black"} */ fontSize={{base:"0.8rem",sm:"0.8rem"}}  borderRadius={2} backdropFilter="auto" backdropBlur="200px" marginTop={"0.8em"} /* letterSpacing={"0.1rem"} */ paddingLeft={2} paddingRight={2} paddingY={2}>Adamthomasosterlund@gmail.com <Hide below="sm"><Button  fontWeight={"normal"} border={20} marginRight={-7} marginTop={-1} w={0} h={0}  onClick={() =>  navigator.clipboard.writeText('Adamthomasosterlund@gmail.com')}><Text color={"gray.600"} borderColor={"gray.200"} borderRadius={"4px"} border={"1px"}  _hover={{color:"green"}}>copy</Text></Button></Hide></Box>

</Box> 
<Box>

</Box>

</Center>
{/* down arrow */}
{/* {isVisible && (
<Box className='down-arrow' transition={"1s"} textAlign={"center"} fontSize={20} fontWeight={"black"} color={"white"} padding={2} marginTop={20} display={"flex"} justifyContent={"center"}> Work Bellow</Box>)} */}
       </Container> 
       <Box zIndex={2} h={"9em"} w={"100vw"} marginTop={-20} marginBottom={-20} className='gappe'></Box>
      </Center>
   
    
     </Box>
     
      </>
    );
  }
  

  
  export const Blob = (props) => {
    return (
        
      <Icon
        width={{base:"120%",md:'100%'}}
        viewBox="0 0 578 440"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
  
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
         
         
          fill='rgb(247, 202, 24, 0.8)'
        />
      </Icon>
      
    );
  };