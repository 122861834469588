/* import { ReactNode } from 'react'; */

import { Link as Linkos } from 'react-scroll';

/* import{theme} from "/Users/adamosterlund/Desktop/ground/my-app/src/theme.fonts" */

import {
  Box,
  Flex,
  
   Text,
  Button,
  HStack,
  Link,
  IconButton,
  
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
 
  useDisclosure,

  
} from '@chakra-ui/react';

import { HamburgerIcon} from '@chakra-ui/icons';

const Links = ['Dashboard', 'Projects', 'Contact'];

const NavLink = ({ children }/* : { children: ReactNode } */) => (
    <Button variant='link'> 
   <Link   > 
  <Linkos
  activeclass={"active"}
  className={"navinact"}
    px={2}
    py={1}
    rounded={'md'}
   
    to={children}
    offset={20}
    smooth={true}
    spy={true}
    
    
    >
    {children} 
  </Linkos>
  </Link> 
  </Button> 
);

export default function Header() {
  
    const { isOpen,   onToggle } = useDisclosure()
  


  return (
    <>
    
          
         
   
        
      <Flex  zIndex={3} id="firsta" display={{ /* base: 'none', */ md: 'flex' }} /* h={16} */  /* bg={"blue"} */alignItems={'center'}  justify={'center'} pos={"relative"} top={"5"} left={"0"} bg={isOpen ? "#33FF68" : "r#45FF78"} >
        
      
      <Menu /* id="boxers"  */top="50%" alignItems={"flex-start"} justifyContent={"center"} pos={"fixed"}  spacing={12}  ml={3}mt={3} h={16} gap={3}  > {({ isOpen }) => (
    <>
      <MenuButton isActive={isOpen} as={Button} rightIcon={isOpen ? <HamburgerIcon /> : <Box>x</Box>}>
       {/*  {isOpen ? 'Close' : 'Open'} */}
      </MenuButton>
      <MenuList>
      {Links.map((link) => (<Box  >
                <MenuItem > <Text  > <NavLink smooth spy to={"skalle"}   key={link}>{link}</NavLink > </Text> </MenuItem></Box>
                
              ))}
      </MenuList>
    </>
  )}
       <MenuButton  onClick={onToggle} bg={"rgba(255, 255, 255, 0.6)"}   mt={2} ml={2}  pos={"fixed"}  display={{base:"flex", md: 'none' }} as={IconButton} >{!isOpen  ? <HamburgerIcon   /> : <Box >x</Box>  } 
   

  
   </MenuButton> 
   {/* hamburger nedanför */}
  <MenuList minW={50}bg={"rgba(255, 255, 255, 0.2)"}  flexDirection={"column"} backdropFilter="auto" backdropBlur="2px" display={{base:"flex", md:'none' }}>
  {Links.map((link) => (<Box  >
               {/*  <MenuItem > <Text  >  */}<NavLink smooth spy to={"skalle"}   key={link}>{link}</NavLink >{/*  </Text> </MenuItem> */}</Box>
                
              )) }
  
  </MenuList> 
  </Menu>
 
          <HStack zIndex={8} backgroundBlendMode={"multiply"}display={{ base: 'none',  md: 'flex' }} id="1"spacing={8}  alignItems={'center'} align={"center"}  justifyContent={'center'} pos={"fixed"}   
                 >
          {/* navigationbar top */}
          <Box bg={"rgba(104, 104, 104, 0.2)"} 
          
             borderRadius={"0px 0px 10px 10px"}
             textColor={"black"}
             border={5}
             padding={22}
             transition={"2s"}
            
              _hover={
                {padding:"30",
              transition:"0.5s",
              bg:"rgba(0, 0, 0, 0.5)",
            opacity:"0.9"}
              }>
          
            <HStack id="2"
             
            width={"100%"}
            align={"center"}
              justify={"center"}
              as={'nav'}
              spacing={4}
              
             
              >
              {Links.map((link) => (
                <NavLink offset={-90} smooth spy to={"skalle"}    key={link} >{link}</NavLink>
                
              ))}
              
            </HStack>
            </Box>
          </HStack>
          
          
          <Flex alignItems={'center'}  >
           
     
          </Flex>
        </Flex>
   
     
   
    </>
  );
}