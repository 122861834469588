import { Box, Button} from '@chakra-ui/react';

import { useEffect, useState } from 'react';

  export default function Contact() {
     
      const [isVisible, setIsVisible] = useState(false);
    
      const scrollToTop = async() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
      useEffect(() => {
        const toggleVisibility = () => {
          if (window.pageYOffset > 1000) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
    
        window.addEventListener('scroll', toggleVisibility);
    
        return () => window.removeEventListener('scroll', toggleVisibility);
      }, []);
    
  
    return ( 
<>
 <Box /* background={"teal"} */  h={"2000"}  id="Contact">under prod</Box>
        <Box display={"flex"} backgroundColor={"gray.200"} justifyContent={"center"} alignContent={"center"}  >end</Box>
        {isVisible && (
        <Box
          onClick={scrollToTop}
          
          position='fixed'
          bottom='20px'
          right={['2', '8']}
          transition={"2s"}
          zIndex={3}>
          <Button
          
            size={'sm'}
           
            variant='solid'>
            Scroll To Top
          </Button>
        </Box>
      )}
     
        
    
        </>
    )
    
}
