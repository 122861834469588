import React from "react";
import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Gap from "./components/Gap";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <Header />

        <Hero />
        <Gap />

        <Projects />

        <Contact />
      </ChakraProvider>
    </>
  );
}

export default App;
