import { React, useState } from "react";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import {
  Box,
  Stack,
  Container,
  Image,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  useColorModeValue,
  Grid,
 /*  Button, */
  Link,
  Icon,
  GridItem,
} from "@chakra-ui/react";
import third from"../assets/third.webp";
import second from "../assets/terazzo.webp";
import { motion } from "framer-motion";
const backimg1 = "https://i.postimg.cc/69yh1Q7r/sands.webp"
const backimg2 = second/* "https://i.postimg.cc/Tfj7ZzFH/Vita-Rosa-Grande-Honed.webp" */
const backimg3 = third/* "https://i.postimg.cc/by2LLW1g/pexels-photo-4709408.webp" */
function Projects() {
/*  */
/* old */
/* const [text, setText] = useState("En text") */
/* function changeText(text) {
  setText("En längre text")
  console.log(text)
} */
  const colors = useColorModeValue(
    [
      backimg1,
      backimg2,
      backimg3,
    ],
    ["red.900", "teal.900", "blue.900"]
  );

  const [tabIndex, setTabIndex] = useState(0);
  const bg = colors[tabIndex];

  return (
    <>
   {/*  <Container minWidth={"100vw"}> */}
   <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1} >
  
  
   
      <Container
      
      /* backgroundColor={"gray.200"} */
      
        display={"flex"}
        flexDirection={{ base: "row", sm: "column" }}
       /*  bgImage={`url(${bg})`} */
        /* transition={"0.3s ease-in-out!important"} */
        /* bgSize={"cover"} */
        maxW={"100vw"}
        background={"gray.400"}
        marginTop={0}
        justifyContent={"center"}
        /* backgroundImage={bg} */
        /* width={"120vw"} */
        backgroundSize={"100%"}
      >
        <MouseParallaxChild factorX={0.2} factorY={0.2} style={{
                background:
                  `url(${bg})`,
                   transition:"background 0.6s ease-in", 
                backgroundPositionY: "50%",
                transform: "scale(1.2)",
                position: "absolute",
                /* filter: "blur(4px) ", */
                backgroundSize: "120%",
                backgroundRepeat: "repeat",
                width: "100%",
                height: "100%",
                backfaceVisibility: "hidden",
                marginLeft:"-2px"
              }}>
    
  </MouseParallaxChild> 
    
    
  
        <Stack
       
        
        
    /*       backgroundColor={"red"} */
          display={"flex"}
          marginTop={""}
          h={"900"}
          justifyContent={"center"}
          id="Projects"
          activeclass={"active"}
        >
            
          <Box
           /*  maxW={"100vw"} */
           
            display={"flex"}
            justifyContent={"center"}
            marginTop={2}
            zIndex={2}
            
            
          >
         
            <Tabs /* maxW={"100vw"} */ /* padding={-32}  */paddingTop={{base:"-5", sm:"20"}} /* isLazy */  onChange={(index) => setTabIndex(index)}  >
              <Box
             
                display={"flex"}
                justifyContent={"center"}
                
                /* className="worktabs" */
                
                
              >
                <TabList transition={"0.2s"} maxW={"100vw"} >
                  <Tab
                  marginX={-2}
                  _selected={{color:"#003ec4"}}
                    transition={"0.5s"}
                    fontSize={{ base: "1.5rem", sm: "1.5rem", md: "2rem" }}
                    letterSpacing={{base:"-0.1rem", md:""}}
                    
                    
                  >
                    Projects
                  </Tab>
                  <Tab
                  
                  marginX={-2}
                    _selected={{color:"green.600", background:"radial-gradient(circle, #e7e7e77a 0%, #ffffff55 50%, #d6d6d611 100%)"}}
                    transition={"0.5s"}
                    fontSize={{ base: "1.5rem", sm: "1.5rem", md: "2rem" }}
                    letterSpacing={{base:"-0.1rem", md:"tighter"}}
                  >
                    Education
                  </Tab>
                  <Tab
                  marginX={-2}
                    _selected={{color:"red.400", bgImage:"https://bless.wiki/images/c/cd/Fish_096.png"}}
                    transition={"0.5s"}
                    fontSize={{ base: "1.5rem", sm: "1.5rem", md: "2rem" }}
                    letterSpacing={{base:"-0.1rem", md:"tighter"}}
                  >
                    Hobbies
                  </Tab>
                </TabList>
              </Box><MouseParallaxChild  factorX={0.1} factorY={0.2} >
              <TabPanels /* p="2rem" */ paddingTop={10} h={{base:"md"}}>
                <TabPanel>
                  {" "}
                  <Grid
                  
                    templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                    gap={4}
                    /* display={"flex"}
                    justifyContent={"center"} */
                  >
                    {" "}
                    <GridItem
                      /* w={"100%"}
                      h={20} */
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      {" "}
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ delay: 0.6 }}
                      >
                        <Box>
                          
                          <Image
                            display={"flex"}
                            justifyContent={"center"}
                            maxW={{ base: "8em", xl: "15em" }}
                            transition={"0.2s"}
                            objectFit={"cover"}
                            /* _onhover={{background:"red"}} */
                            borderRadius={{
                              base: "5px",
                              sm: "20px",
                              md: "50px",
                            }}
                            src="https://i.postimg.cc/YC1VVrnz/tsdashboard.webp"
                          >
                      {/*       {_hover={{background-color:"red"}}} */}
                          </Image>
                        
                          <Text
                            display={"flex"}
                            textDecoration={"underline"}
                            textUnderlineOffset={2}
                            justifyContent={"center"}
                          >
                            {" "}
                            Dashboard{" "}
                          </Text>
                          <Text
                            color={"gray.800"}
                            fontSize={"80%"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            {" "}
                            Typescript{" "}
                          </Text>
                          <Box display={"flex"} justifyContent={"center"} >
                          <Link display={"flex"} justifyContent={"center"} href={"https://github.com/Adamsalvius/Dashboard"} isExternal>
                          <Icon /* animation={"fadeIn 2.5s ease-in  "} */  w={{base:"1em",sm:"1.5em",md:"2em"} } height={"auto"}  color='
                          gray.700' transition={"0.8s"} /* _hover={{base:{w:"5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}} */>
                          <path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                          </Icon>
                          </Link>
                          <Link marginX={2} alignItems={"center"} display={"flex"} justifyContent={"center"} href={"https://strong-pika-9a9d46.netlify.app/"} isExternal>Demo</Link>
                          </Box>
                        </Box>
                      </motion.div>
                    </GridItem>
                    <GridItem /* w={"100%"} h={20} */display={"flex"}
                    justifyContent={"center"}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ delay: 0.6 }}
                      >
                        <Box >
                          <Box>
                          <Image
                            display={"flex"}
                            justifyContent={"center"}
                            transition={"0.2s"}
                            height={{base:120, sm:125, xl:225}}
                            maxW={{ base: "8em", xl: "15em" }}
                            objectFit={"cover"}
                            borderRadius={{
                              base: "5px",
                              sm: "20px",
                              md: "50px",
                            }}
                            src="https://i.postimg.cc/sgfsRf1y/figmaux.webp"
                          ></Image>
                          </Box>

                          <Text
                            display={"flex"}
                            justifyContent={"center"}
                            textDecoration={"underline"}
                            textUnderlineOffset={2}
                          >
                            {" "}
                            UX-project
                          </Text>
                          <Text
                            color={"gray.800"}
                            fontSize={"80%"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            {" "}
                            figma, miro{" "}
                          </Text>
                          <Box display={"flex"} justifyContent={"center"} >
                          <Link  display={"flex"} justifyContent={"center"} href={"https://miro.com/app/board/o9J_luDGBGw=/"} isExternal>
                          <Icon /* animation={"fadeIn 2.5s ease-in  "} */  w={{base:"1.5em",sm:"1.5em",md:"1.5em"} } height={"auto"}  color='
                          gray.700' transition={"0.8s"} /* _hover={{base:{w:"5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}} */>
                          <path fill="currentColor" d="M17.392 0H13.9L17 4.808 10.444 0H6.949l3.102 6.3L3.494 0H0l3.05 8.131L0 24h3.494L10.05 6.985 6.949 24h3.494L17 5.494 13.899 24h3.493L24 3.672 17.392 0z"/>
                          </Icon>
                          </Link>
                          <Link  display={"flex"} justifyContent={"center"} href={"https://www.figma.com/proto/DVZN66li9F9tw6cjhCXpfP/UX?node-id=139%3A1465"} isExternal>
                          <Icon /* animation={"fadeIn 2.5s ease-in  "} */  w={{base:"1em",sm:"1.5em",md:"1.5em"} } height={"auto"}  color='
                          gray.700' transition={"0.8s"} /* _hover={{base:{w:"5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}} */>
                          <path fill="currentColor"  d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z"></path><path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z"></path><path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z"></path><path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z"></path><path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z"/>
                          </Icon>
                          </Link>
                          <Link marginX={2} alignItems={"center"} display={"flex"} justifyContent={"center"} href={"https://www.figma.com/file/DVZN66li9F9tw6cjhCXpfP/UX?node-id=139%3A1465&t=1tY6QDoPfeM0LHzk-1"} isExternal>Board</Link>
                          </Box>
                        </Box>
                      </motion.div>
                    </GridItem>
                    <GridItem /* w={"100%"} h={20} */display={"flex"}
                    justifyContent={"center"}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ delay: 0.6 }}
                      >
                        <Box >
                          <Image
                            display={"flex"}
                            justifyContent={"center"}
                            transition={"0.2s"}
                            maxW={{ base: "8em", xl: "15em" }}
                            objectFit={"cover"}
                            borderRadius={{
                              base: "5px",
                              sm: "20px",
                              md: "50px",
                            }}
                            src="https://i.postimg.cc/wjVHSQ3v/todovue.webp"
                          ></Image>

                          <Text
                            display={"flex"}
                            justifyContent={"center"}
                            textDecoration={"underline"}
                            textUnderlineOffset={2}
                          >
                            {" "}
                            Todo
                          </Text>
                          <Text
                            color={"gray.800"}
                            fontSize={"80%"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            {" "}
                            vue{" "}
                          </Text>
                          <Box display={"flex"} justifyContent={"center"} >
                          <Link display={"flex"} justifyContent={"center"} href={"https://github.com/Adamsalvius/todovue"} isExternal>
                          <Icon /* animation={"fadeIn 2.5s ease-in  "} */  w={{base:"1em",sm:"1.5em",md:"2em"} } height={"auto"}  color='
                          gray.700' transition={"0.8s"} /* _hover={{base:{w:"5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}} */>
                          <path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                          </Icon>
                          </Link>
                          <Link marginX={2} alignItems={"center"} display={"flex"} justifyContent={"center"} href={"https://main--subtle-taiyaki-5a74ee.netlify.app/"} isExternal>Demo</Link>
                          </Box>
                        </Box>
                      </motion.div>
                    </GridItem>
                    {/* next */}
                    <GridItem /* w={"100%"} h={20} */display={"flex"}
                    justifyContent={"center"}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ delay: 0.6 }}
                      >
                        <Box >
                          <Image
                            display={"flex"}
                            justifyContent={"center"}
                            transition={"0.2s"}
                            maxW={{ base: "8em", xl: "15em" }}
                            height={{base:125, xl:230}}
                            objectFit={"cover"}
                            borderRadius={{
                              base: "5px",
                              sm: "20px",
                              md: "50px",
                            }}
                            src="https://i.postimg.cc/nzNRLr05/timetrack.webp"
                          ></Image>

                          <Text
                            display={"flex"}
                            justifyContent={"center"}
                            textDecoration={"underline"}
                            textUnderlineOffset={2}
                          >
                            {" "}
                            Timetracker
                          </Text>
                          <Text
                            color={"gray.800"}
                            fontSize={"60%"}
                            display={"flex"}
                            justifyContent={"center"}
                            
                          >
                            {" "}
                            Axios uuid dayjs chakra react{" "}
                          </Text>
                          <Box display={"flex"} justifyContent={"center"} >
                          <Link display={"flex"} justifyContent={"center"} href={"https://github.com/Adamsalvius/kks1"} isExternal>
                          <Icon /* animation={"fadeIn 2.5s ease-in  "} */  w={{base:"1em",sm:"1.5em",md:"2em"} } height={"auto"}  color='
                          gray.700' transition={"0.8s"} /* _hover={{base:{w:"5em", h:"auto"},sm:{w:"4em", h:"auto"}, md:{w:"4.5em", h:"auto"}}} */>
                          <path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                          </Icon>
                          </Link>
                          <Link marginX={2} alignItems={"center"} display={"flex"} justifyContent={"center"} href={"https://peppy-halva-da6ee6.netlify.app/"} isExternal>Demo</Link>
                          </Box>
                        </Box>
                      </motion.div>
                    </GridItem>
                    {/* <GridItem display={"flex"}
                    justifyContent={"center"}>
                      <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: false }}
                        transition={{ delay: 0.6 }}
                      >
                        <Box >
                          <Image
                            display={"flex"}
                            justifyContent={"center"}
                            maxW={{ base: "20vw", xl: "15vw" }}
                            transition={"0.2s"}
                            objectFit={"cover"}
                            borderRadius={{
                              base: "5px",
                              sm: "20px",
                              md: "50px",
                            }}
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Mob_prog.jpg/800px-Mob_prog.jpg"
                          ></Image>

                          <Text
                            display={"flex"}
                            justifyContent={"center"}
                            transition={"0.6s"}
                          >
                            {" "}
                            <Text
                              display={"flex"}
                              justifyContent={"center"}
                              textDecoration={"underline"}
                              textUnderlineOffset={2}
                            >
                              timetracker
                            </Text>
                          </Text>
                        </Box>
                      </motion.div>
                    </GridItem> */}
                  </Grid>{" "}
                </TabPanel>
                
                <TabPanel>
                  
                  <Box>
                    
                    
             {/*        <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1}>
  <MouseParallaxChild factorX={0.3} factorY={0.5}>
    <img src="https://i.postimg.cc/69yh1Q7r/sands.webp" alt="" />
  </MouseParallaxChild>
  <MouseParallaxChild factorX={0.7} factorY={0.8}>
    <img src="https://i.postimg.cc/69yh1Q7r/sands.webp" alt="" />
  </MouseParallaxChild>
</MouseParallaxContainer> */}


</Box>
                placeholder1
                </TabPanel>
                <TabPanel>placeholder</TabPanel>
              {/*   <Button onClick={changeText}>heja</Button> */}
              </TabPanels>
              </MouseParallaxChild> 
            </Tabs>
          </Box>

          <Box display={"flex"} id={"right"} transition={"0.2s"}></Box>

          <Box pos={"absolute"} >
            <Box w={"90vw"}></Box>
          </Box>

          <div
            pos={"absolute"}
            top={0}
            display={"flex"}
            width={100}
            height={100}
          ></div>
        </Stack>
        
      </Container>
      
      </MouseParallaxContainer>
     {/*  </Container> */}
    </>
  );
}

export default Projects;
